<template>
  <div>
    <base-loading v-if="!ready" />
    <v-container
      v-else
      fluid
    >
      <v-row
        class="pt-0 pb-0 py-0"
      >
        <v-col
          cols="12"
          sm="6"
          lg="3"
          xl="3"
          class="pt-0 pb-0"
        >
          <material-stats-card
            title="Fornecedores"
            :value="String(dashboard.fornecedores)"
            icon="mdi-account-supervisor"
            color="blue darken-4"
            sub-icon="mdi-clock-outline"
            sub-icon-color="info"
            sub-text="Até o momento"
            elevation="3"
            :action="fornecedores"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="3"
          xl="3"
          class="pt-0 pb-0"
        >
          <material-stats-card
            :title="'Fornecedores s/ movimento ' + moment(new Date()).format('MMM/YY')"
            :value="String(dashboard.mvtfornecedores.length)"
            icon="mdi-clipboard-check"
            color="indigo darken-1"
            sub-icon="mdi-clock-outline"
            sub-icon-color="info"
            sub-text="Até o momento"
            :action="mvtFornecedores"
            elevation="3"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="3"
          xl="3"
          class="pt-0 pb-0"
        >
          <material-stats-card
            title="Associados"
            :value="String(dashboard.associados)"
            icon="mdi-account-group"
            color="blue darken-4"
            sub-icon="mdi-clock-outline"
            sub-icon-color="info"
            sub-text="Até o momento"
            elevation="3"
            :action="associados"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="3"
          xl="3"
          class="pt-0 pb-0"
        >
          <material-stats-card
            :title="'Associados s/ movimento ' + moment(new Date()).format('MMM/YY')"
            :value="String(dashboard.mvtassociados.length)"
            icon="mdi-clipboard-check-multiple"
            color="indigo darken-1"
            sub-icon="mdi-clock-outline"
            sub-icon-color="info"
            sub-text="Até o momento"
            :action="mvtAssociados"
            elevation="3"
          />
        </v-col>
        <v-col
          cols="12"
          lg="6"
          xl="6"
        >
          <material-card
            color="primary"
            :title="'Fornecedores com movimento ' + moment(new Date()).format('MMMM/YY')"
            icon="mdi-account-supervisor"
            elevation="3"
            style="min-width: '100%'; min-height: 435px overflow-x: auto; padding-buttom: 5px;"
          >
            <v-row
              class="pt-11"
              justify="center"
              style="overflow-y: hidden;"
            >
              <vue-speedometer
                :max-segment-labels="5"
                :segments="5"
                :min-value="0"
                value-format="d"
                :max-value="dashboard.fornecedores"
                :value="dashboard.fornecedores - dashboard.mvtfornecedores.length"
                current-value-text="Fornecedores Positivados: ${value}"
                :width="455"
                needle-color="#000080"
                needle-transition="easeElastic"
                urrent-value-placeholder-style="#{value}"
                style="min-width: 480px;"
              />
            </v-row>
            <template
              v-slot:actions
            >
              <v-icon
                color="info"
                size="16"
                class="ml-1 mr-1"
              >
                mdi-clock-outline
              </v-icon>
              <span
                class="caption grey--text font-weight-light"
              >Até o momento </span>
            </template>
          </material-card>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          xl="6"
        >
          <material-card
            color="primary"
            :title="'Associados com movimento ' + moment(new Date()).format('MMMM/YY')"
            icon="mdi-account-group"
            elevation="3"
            style="min-width: '100%'; min-height: 435px overflow-x: auto; padding-buttom: 5px;"
          >
            <v-row
              class="pt-11"
              justify="center"
              style="overflow-y: hidden;"
            >
              <vue-speedometer
                :max-segment-labels="5"
                :segments="5"
                :min-value="0"
                value-format="d"
                :max-value="dashboard.associados"
                :value="dashboard.associados - dashboard.mvtassociados.length"
                current-value-text="Associados Positivados: ${value}"
                :width="455"
                needle-color="#000080"
                needle-transition="easeElastic"
                urrent-value-placeholder-style="#{value}"
                style="min-width: 480px;"
              />
            </v-row>
            <template
              v-slot:actions
            >
              <v-icon
                color="info"
                size="16"
                class="ml-1 mr-1"
              >
                mdi-clock-outline
              </v-icon>
              <span
                class="caption grey--text font-weight-light"
              >Até o momento </span>
            </template>
          </material-card>
        </v-col>
        <v-col
          cols="12"
          class="pl-6 pr-6 graficoLine"
        >
          <material-chart-card
            v-if="dashboard.acumuladoFaturamento"
            :data="dashboard.acumuladoFaturamento.data"
            :options="optionsBar"
            type="Line"
            color="primary"
            title="Evolução do Faturamento Mensal"
            icon="mdi-chart-bell-curve"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            sub-text-left="Últimos 12 meses"
            elevation="4"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex'
  import MaterialStatsCard from '../../components/base/MaterialStatsCard'
  import MaterialChartCard from '../../components/base/MaterialChartCard'
  import MaterialCard from '../../components/base/MaterialCard'
  import DashboardStore, { BOOTSTRAP } from '@/store/modules/dashboard'
  import moment from 'moment'
  import VueSpeedometer from 'vue-speedometer'
  import Tooltip from 'chartist-plugin-tooltips-updated'
  import Legend from 'chartist-plugin-legend'
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    name: 'DashboardGestor',
    components: {
      MaterialStatsCard,
      MaterialChartCard,
      VueSpeedometer,
      MaterialCard,
    },
    data () {
      return {
        styles: {
          width: '500px',
          height: '300px',
          background: '#2a2744',
        },
        moment,
        optionsBar: {
          axisX: {
            offset: 30,
          },
          axisY: {
            onlyInteger: false,
            offset: 120,
            labelInterpolationFnc: function (value) {
              return 'R$ ' + currencyFormatter(value, 2)
            },
          },
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            tension: 0,
          }),
          height: 290,
          low: 0,
          showArea: true,
          plugins: [
            Legend(),
            Tooltip({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return meta + '<br />' + currencyFormatter(value, 2) },
            }),
          ],
          chartPadding: {
            top: 30,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }
    },
    computed: {
      ...mapState({ user: state => state.user }),
      ...mapState('dashboard', ['ready', 'dashboard']),
      ...mapGetters(['hasPermission']),
    },
    created () {
      if (!this.$store.hasModule('dashboard')) { this.$store.registerModule('dashboard', DashboardStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('dashboard', [BOOTSTRAP]),
      mvtFornecedores () {
        const cnpjs = []
        this.dashboard.mvtfornecedores.forEach(element => {
          cnpjs.push(element.CNPJ)
        })
        this.$router.push({ path: 'fornecedores/?cnpjs=' + JSON.stringify(cnpjs) }).catch(() => {})
      },
      mvtAssociados () {
        const cnpjs = []
        this.dashboard.mvtassociados.forEach(element => {
          cnpjs.push(element.CNPJ)
        })
        this.$router.push({ path: 'associados/?cnpjs=' + JSON.stringify(cnpjs) }).catch(() => {})
      },
      fornecedores () {
        this.$router.push({ path: 'fornecedores' }).catch(() => {})
      },
      associados () {
        this.$router.push({ path: 'associados' }).catch(() => {})
      },
    },
  }
</script>

<style lang="scss">

.graficoLine .ct-square {
  height: 290px;
}

.graficoLine .v-card--material-chart .v-card--material__heading {
  max-height: 315px;
}

.graficoPieTec .v-card--material-chart .v-card--material__heading {
  max-height: 315px;
}

.graficoPieCat .v-card--material-chart .v-card--material__heading {
  max-height: 315px;
}

.graficoLine .v-card--material-chart .theme--dark.v-card {
  background-color: white !important
}

</style>
